<template>
  <div :class="'content ' + (show ? 'fade-in' : 'fade-out')">
    <div v-if="!loading">
      <ion-list v-if="releases.length > 0">
        <ion-item v-for="_release in releases" :key="_release.uid"
                  button @click="openRelease(_release.package, _release.version)"
                  detail="true">
          <ion-thumbnail slot="start">
            <img :src="getImageForRegistry(_release.registry)">
          </ion-thumbnail>
          <ion-label>
            <h2> {{_release.package}}  </h2>
            <h3> {{_release.version}} </h3>
            <p> by {{_release.user}} &bull; {{ TimePassedPipe(_release.timestamp.toDate()) }} </p>
          </ion-label>
        </ion-item>
      </ion-list>

      <div v-else class="nothing-found">
        <img src="/assets/nothing-found-light.gif" widht="384" height="384" />
        <div class="hint">
          Es wurgen keine Releases gefunden
        </div>
      </div>
    </div>

    <div v-else class="list-skeleton">
      <ion-list>
        <ion-item v-for="x in (new Array(15))" :key="x">
          <ion-thumbnail slot="start">
            <ion-skeleton-text animated />
          </ion-thumbnail>
          <ion-label>
            <h3> <ion-skeleton-text animated style="width: 20%; height: 21px;" /> </h3>
            <p> <ion-skeleton-text animated style="width: 40%; height: 12px;" /> </p>
          </ion-label>
        </ion-item>
      </ion-list>
      <div class="inner-shadow" />
    </div>
  </div>
</template>

<script lang="ts" src="./release-list.ts" />
<style scoped src="./release-list.css" />
