
import { defineComponent, onBeforeMount, ref } from 'vue';
import {
  IonList,
  IonItem,
  IonThumbnail,
  IonLabel,
  IonSkeletonText,
} from '@ionic/vue';

import firebase from 'firebase';
import { useRouter } from 'vue-router';

import { TimePassedPipe } from '@/pipes/time-passed.pipe';

export default defineComponent({
  name: 'ReleaseList',
  components: {
    IonList,
    IonItem,
    IonThumbnail,
    IonLabel,
    IonSkeletonText,
  },
  setup() {
    const router = useRouter();
    const firestore = firebase.firestore();
    const releases: Array<any> = [];

    const loading = ref(true);
    const show = ref(true);

    const loadReleases = async () => {
      loading.value = true;
      releases.length = 0;

      const releasesSnapshot = await firestore
        .collection('release')
        .orderBy('timestamp', 'desc')
        .limit(20)
        .get();

      releasesSnapshot.forEach(_release => releases.push({uid: _release.id, ..._release.data()}));
      show.value = false;
      console.log(releases);

      setTimeout(() => {
        loading.value = false;
        show.value = true;
      }, 250);
    };

    const openRelease = (_package: string, _version: string): void => {
      router.push(`/home/package/${_package}/${_version}`);
    };

    const getImageForRegistry = (registry: string) => {
      return `https://firebasestorage.googleapis.com/v0/b/project-release-2b33c.appspot.com/o/package-types%2F${registry}_64x64.png?alt=media`;
    };

    onBeforeMount(() => {
      loadReleases();
    });

    return {
      loading,
      show,
      releases,
      openRelease,
      getImageForRegistry,
      TimePassedPipe,
    };
  }
});
